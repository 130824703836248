const SecretToolsData = [    
        
   {
          title: "🗂 A collection of FREE Gen AI courses",
   content: `
1️⃣ Generative AI for Beginners course : building generative artificial intelligence apps.

2️⃣ Generative AI Fundamentals course : getting to know the basic principles of generative artificial intelligence.

3️⃣ Intro to Gen AI course : from learning large language models to understanding the principles of responsible artificial intelligence.

4️⃣ Generative AI with LLMs course : Learn business applications of artificial intelligence with AWS experts in a practical way.

5️⃣ Generative AI for Everyone course : This course tells you what generative artificial intelligence is, how it works, and what uses and limitations it has.`
        }, 

        {
          title: "🍭 Future Trends in Artificial Intelligence 🍭",
          content: `1. AI in healthcare: With the increasing demand for personalized medicine and precision healthcare, AI is expected to play a crucial role in analyzing large amounts of medical data to diagnose diseases, develop treatment plans, and predict patient outcomes.

2. AI in finance: AI-powered solutions are expected to revolutionize the financial industry by improving fraud detection, risk assessment, and customer service. Robo-advisors and algorithmic trading are also likely to become more prevalent.

3. AI in autonomous vehicles: The development of self-driving cars and other autonomous vehicles will rely heavily on AI technologies such as computer vision, natural language processing, and machine learning to navigate and make decisions in real-time.

4. AI in manufacturing: The use of AI and robotics in manufacturing processes is expected to increase efficiency, reduce errors, and enable the automation of complex tasks.

5. AI in customer service: Chatbots and virtual assistants powered by AI are anticipated to become more sophisticated, providing personalized and efficient customer support across various industries.

6. AI in agriculture: AI technologies can be used to optimize crop yields, monitor plant health, and automate farming processes, contributing to sustainable and efficient agricultural practices.

7. AI in cybersecurity: As cyber threats continue to evolve, AI-powered solutions will be crucial for detecting and responding to security breaches in real-time, as well as predicting and preventing future attacks.`
        }, 
        {
          title: "🧨 Generative AI Apps",
          content: `• ChatGPT, Pricing: $20/month for GPT-4. Free GPT-3.5.
• Claude, Pricing: $20/month for Claude 3 Opus. Free Claude 3 Sonnet.
• Google Gemini, Pricing: $20/month for Gemini Advanced. Free Gemini.
• Microsoft Copilot, Pricing: $20/month for Copilot +. Free Copilot.
• Perplexity, Pricing: $20/month. Free plan with limited features.
• Pi, Pricing: Free`
        }, 
        {
          title: " Download : ",
          content: ` Sites To Download Files:
1. freepik.com
2. all-free-download.com
3. vecteezy.com
4. freeimages.com
5. rb.gy/613aye

 Sites To Download Mockups:
1. mockupsforfree.com
2. mockupworld.co
3. graphicburger.com
4. zippypixels.com
5. rb.gy/613aye

 Inspiration Sites:
1. inspirationde.com
2. designspiration.net
3. pinterest.com
4. dribbble.com
5. rb.gy/613aye

 Video Sites:
1. mixkit.co
2. coverr.co
3. motionplaces.com
4. videezy.com
5. rb.gy/613aye
`
        },
        
        {
          title: " Photo Site ",
          content: ` Photo Sites Without Background:
1. cleanpng.com
2. pngimg.com
3. footyrenders.com
4. pngtree.com
5. rb.gy/613aye

 Photo Sites:
1. unsplash.com
2. pexels.com
3. pixabay.com
4. stocksnap.io
5. burst.shopify.com.
6. rb.gy/613aye`
        },
        
        
        {
          title: " 100 Best Useful Sites  ",
          content: ` 

 100 Best Useful Sites 

1. screenr.com – Record movies of your desktop and send them straight to YouTube.

2. bounceapp.com – For capturing full length screenshots of web pages.

3. Goo. gl – Shorten long URLs and convert URLs into QR codes.

4. untiny.me – Find the original URLs that’s hiding behind a short URLs.

5. localti.me – Know more than just the local time of a city

6.copypastecharacter.com– Copy-paste special characters that aren’t on your keyboard.

7. topsy.com –A better search engine for twitter.

8. fb.me/AppStore –Search iOS apps without launching iTunes.

9. iconfinder.com –The best place to find icons of all sizes.

10. office.com –Download templates, clipart and images for your Office documents.

Join Paid Premium Channels Now



11. woorank.com –everything you wanted to know about a website.

12. virustotal.com –Scan any suspicious file or email attachment for viruses.

13. wolframalpha.com– Gets answers directly without searching .

14.printwhatyoulike.com– Print web pages without the clutter.

15. joliprint.com –Reformats news articles and blog content as a newspaper.

16. isnsfw.com – When you wish to share a NSFW page but with a warning.

17. eggtimer.com – A simple online timer for your daily needs.

18. coralcdn.org – If a site is down due to heavy traffic, try accessing it through coral CDN.

19. random.org – Pick random numbers, flip coins, and more.

20. mywot.com –Check the trust level of any website .


21. viewer.zoho.com –Preview PDFs and Presentations directly in the browser.

22. tubemogul.com –Simultaneously upload videos to YouTube and other video sites.

23. truveo.com – The best place for searching web videos.

24. scr.im – Share your email address online without worrying about spam.

25. spypig.com – Now get read receipts for your email.

26. sizeasy.com –Visualize and compare the size of any product.

27. whatfontis.com –quickly determine the font name from an image.

28. fontsquirrel.com –A good collection of fonts – free for personal and commercial use.

29. regex.info – Find data hidden in your photographs .

30. tineye.com – This is like an online version of Google Googles.



31. iwantmyname.com– Helps you search domains across allTLDs.

32. tabbloid.com –Your favorite blogs delivered as PDFs.

33. join. me – Share your screen with anyone over the web.

34. onlineocr.net –Recognize text from scanned PDFs and images – see other OCR tools.

35. flightstats.com –Track flight status at airports worldwide.

36. wetransfer.com –For sharing really big files online.

37. pastebin.com – A temporary onlineclipboard for your text and code snippets.

38.polishmywriting.com –Check your writing for spelling or grammatical errors.

39.awesomehighlighter.com– Easily highlight the important parts of a web page.

40. typewith.me –Work on the same document with multiple people.


41.whichdateworks.com –Planning an event? Find a date that works for all.

42. everytimezone.com– A less confusing view of the world time zones.

43. warrick.cs.odu.edu– You’ll need this when your bookmarked web pages are deleted.

44. gtmetrix.com – The perfect tool for measuring your site performance online.

45. imo.im – Chat with your buddies on Skype,Facebook, GoogleTalk, etc from one place.

46.translate.google.com– Translate web pages,PDFs and Office documents.

47. youtube.com/leanback – Sit back and enjoy YouTube videos in full-screen mode.

48. similarsites.com –Discover new sites that are similar to what you like already.

49. wordle.net – Quicksummarize long pieces of text with tag clouds.

50. bubbl.us – Create mind-maps, brainstorm ideas in the browser.



51. kuler.adobe.com –Get color ideas, also extract colors from photographs.

52. followupthen.com– Setup quick reminders via email itself.

53. lmgtfy.com – When your friends are too lazy to use Google on their own.

54. tempalias.com –Generate temporary email aliases, better than disposable email.

55. pdfescape.com –Lets you can quickly edit PDFs in the browser itself.

56. faxzero.com – Send an online fax for free– see more fax services.

57 feedmyinbox.com –Get RSS feeds as an email newsletter

58. isendr.com –Transfer files without uploading to a server.

59. tinychat.com –Setup a private chatroom in micro-seconds.

60. privnote.com –Create text notes that will self-destruct after being read.



61. flightaware.com –Live flight tracking service for airports worldwide.

62. boxoh.com – Track the status of any shipment on Google Maps – alternative.

63. chipin.com – When you need to raise funds online for an event or a cause.

64.downforeveryoneorjustme.com – Is your favorites really offline?

65. example.com – This website can be used as an example in documentation.

66.whoishostingthis.com –Find the web host of any website.

67. google.com/history– Found something on Google but can’t remember it now?

68.errorlevelanalysis.com– Find whether a photo is real or aphotoshopped one.

69. google.com/dictionary – Get word meanings, pronunciations and usage examples.

70.urbandictionary.com –Find definitions of slangs and informal words.





71. seatguru.com –Consult this site before choosing a seat for your next flight.

72. sxc.hu – Download stock images absolutely free.

73.download.com.np-Get all softwares.

74. wobzip.org – Unzip your compressed files online.

75. vocaroo.com –Record your voice with a click.

76. scribblemaps.com– Create custom Google Maps easily.

77. buzzfeed.com –Never miss another Internet meme or viral video.

78. alertful.com –Quickly setup email reminders for important events.

79.encrypted.google.com– Prevent your ISP and boss from reading your search queries.

80. formspring.me –You can ask or answer personal questions here.



81. snopes.com – Find if that email offer you received is real or just another scam.

82. typingweb.com –Master touch-typing with these practice sessions.

83. mailvu.com – Send video emails to anyone using your webcam.

84. ge.tt – Quickly send a file to someone,they can even preview it before downloading.

85. timerime.com –Create timelines with audio, video and images.

86. stupeflix.com –Make a movie out of your images, audio and video clips.

87. aviary.com/myna –An online audio editor that lets you record and remix audio clip.

88. noteflight.com –Print music sheets, write your own music online (review).

89.disposablewebpage.com– Create a temporary web page that self-destruct.

90. namemytune.com– When you need to find the name of a song. `
        }, 
        
      
    ];
    
    export default SecretToolsData;
    