const seleniumCourses = [
    {
      title: "Selenium Basics",
      image: "https://dummyimage.com/200x150/000/fff&text=Selenium1",
      link: "https://example.com/selenium-course-1"
    },
    {
      title: "Advanced Selenium",
      image: "https://dummyimage.com/200x150/000/fff&text=Selenium2",
      link: "https://example.com/selenium-course-2"
    },
  ];
  
  export default seleniumCourses;
  