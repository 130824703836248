import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import hackingData from '../data/hackingData';

// Function to convert newline characters to HTML line breaks
const formatContent = (content) => {
  return content.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));
};

const ArticleDetail = () => {
  const { id } = useParams();
  const articleIndex = parseInt(id, 10);
  const article = hackingData[articleIndex];
  const navigate = useNavigate();

  if (isNaN(articleIndex) || articleIndex < 0 || articleIndex >= hackingData.length) {
    return <h2 className="text-center">Article not found!</h2>;
  }

  return (
    <div className="container mt-5 pt-5">
      <h1 className="text-center my-4">{article.title}</h1>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card">
            <div className="card-body">
              {/* Display content with line breaks */}
              <div className="card-text">
                {formatContent(article.content)}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Back Button */}
      <div className="back-button">
        <button className="btn btn-secondary" onClick={() => navigate(-1)}>
          Back to Hacking Tutorials
        </button>
      </div>
    </div>
  );
};

export default ArticleDetail;
