import React, { useState } from 'react';
import { Button, Form, Modal, Dropdown, DropdownButton, Container, Row, Col, Card, Table,  } from 'react-bootstrap';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';

const TestingSelenium = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedFootballPlayer, setSelectedFootballPlayer] = useState('');
  const [selectedCricketPlayer, setSelectedCricketPlayer] = useState('');

  // Web Table State
  const [rows, setRows] = useState([
    { firstName: 'Cierra', lastName: 'Vega', age: 39, email: 'cierra@example.com', salary: 10000, department: 'Insurance' },
    { firstName: 'Alden', lastName: 'Cantrell', age: 45, email: 'alden@example.com', salary: 12000, department: 'Compliance' },
    { firstName: 'Kierra', lastName: 'Gentry', age: 29, email: 'kierra@example.com', salary: 2000, department: 'Legal' },
  ]);
  
  const [newRow, setNewRow] = useState({
    firstName: '', lastName: '', age: '', email: '', salary: '', department: ''
  });

  const [showAddModal, setShowAddModal] = useState(false);
  const [draggedItem, setDraggedItem] = useState(null);
  const [dropZoneItems, setDropZoneItems] = useState([]);
  const [hovered, setHovered] = useState(false);
  const handleShowPopup = () => setShowPopup(true);
  const handleClosePopup = () => setShowPopup(false);

  
  const handleFootballSelect = (eventKey) => setSelectedFootballPlayer(eventKey);
  const handleCricketSelect = (eventKey) => setSelectedCricketPlayer(eventKey);

  const handleShowAddModal = () => setShowAddModal(true);
  const handleCloseAddModal = () => setShowAddModal(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewRow({ ...newRow, [name]: value });
  };

  const handleAddRow = () => {
    setRows([...rows, newRow]);
    setNewRow({ firstName: '', lastName: '', age: '', email: '', salary: '', department: '' });
    handleCloseAddModal();
  };
  const handleNestedSubmenuClick = () => {
    window.open('https://www.google.com', '_blank');
  };

  //drag sars 
  const handleDragStart = (item) => {
    setDraggedItem(item);
  };
  const handleDragOver = (e) => {
    e.preventDefault(); // Prevent default behavior to allow dropping
  };

  const handleDrop = () => {
    if (draggedItem) {
      setDropZoneItems([...dropZoneItems, draggedItem]);
      setDraggedItem(null); // Reset the dragged item
    }
  };

  // Items to drag
  const draggableItems = [
    { id: 1, label: 'Item 1' },
    { id: 2, label: 'Item 2' },
    { id: 3, label: 'Item 3' },
    { id: 4, label: 'Item 4' },
  ];
  
  return (
    <Container>
      {/* Form Section */}
      <Row className="my-4">
        <Col>
          <Card>
            <Card.Header>
              <h3>Automation Testing Form</h3>
            </Card.Header>
            <Card.Body>
              <Form>
                <Form.Group controlId="formName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter your name" />
                </Form.Group>

                <Form.Group controlId="formCheckbox">
                  <Form.Check type="checkbox" label="Agree to terms and conditions" />
                </Form.Group>

                <Form.Group controlId="formRadio">
                  <Form.Check type="radio" name="gender" label="Male" />
                  <Form.Check type="radio" name="gender" label="Female" />
                </Form.Group>

                <Button variant="primary" onClick={handleShowPopup}>
                  Open Alert Popup
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Modal for Popup */}
      <Modal show={showPopup} onHide={handleClosePopup}>
        <Modal.Header closeButton>
          <Modal.Title>Alert Popup</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you want to proceed?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePopup}>Cancel</Button>
          <Button variant="primary" onClick={handleClosePopup}>OK</Button>
        </Modal.Footer>
      </Modal>

      {/* Dropdown Section */}
      <Row className="my-4">
        <Col>
          <Card>
            <Card.Header>
              <h3>Select Players</h3>
            </Card.Header>
            <Card.Body>
              <DropdownButton id="dropdown-football" title={selectedFootballPlayer || "Football Players"} onSelect={handleFootballSelect}>
                <Dropdown.Item eventKey="Lionel Messi">Lionel Messi</Dropdown.Item>
                <Dropdown.Item eventKey="Cristiano Ronaldo">Cristiano Ronaldo</Dropdown.Item>
                <Dropdown.Item eventKey="Neymar">Neymar</Dropdown.Item>
              </DropdownButton>
              <p className="mt-2">Selected Football Player: {selectedFootballPlayer}</p>

              <DropdownButton id="dropdown-cricket" title={selectedCricketPlayer || "Cricket Players"} onSelect={handleCricketSelect} className="mt-3">
                <Dropdown.Item eventKey="Virat Kohli">Virat Kohli</Dropdown.Item>
                <Dropdown.Item eventKey="MS Dhoni">MS Dhoni</Dropdown.Item>
                <Dropdown.Item eventKey="Sachin Tendulkar">Sachin Tendulkar</Dropdown.Item>
              </DropdownButton>
              <p className="mt-2">Selected Cricket Player: {selectedCricketPlayer}</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Iframe Section */}
      <Row className="my-4">
        <Col>
          <Card>
            <Card.Header>
              <h3>Iframe Section</h3>
            </Card.Header>
            <Card.Body>
              <iframe title="Testing Iframe" width="100%" height="300px" src="https://www.example.com" frameBorder="0"></iframe>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Web Table Section */}
      <Row className="my-4">
        <Col>
          <Card>
            <Card.Header>
              <h3>Web Tables</h3>
              <Button variant="primary" className="mt-2" onClick={handleShowAddModal}>Add</Button>
            </Card.Header>
            <Card.Body>
              {/* Search Bar */}
              {/* <InputGroup className="mb-3">
                <FormControl placeholder="Type to search" />
                <InputGroup.Append>
                  <Button variant="outline-secondary">Search</Button>
                </InputGroup.Append>
              </InputGroup> */}

              {/* Table */}
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Age</th>
                    <th>Email</th>
                    <th>Salary</th>
                    <th>Department</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row, index) => (
                    <tr key={index}>
                      <td>{row.firstName}</td>
                      <td>{row.lastName}</td>
                      <td>{row.age}</td>
                      <td>{row.email}</td>
                      <td>{row.salary}</td>
                      <td>{row.department}</td>
                      <td>
                        <Button variant="warning" className="mr-2">
                          <FaEdit />
                        </Button>
                        <Button variant="danger">
                          <FaTrashAlt />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Modal for Adding a New Row */}
      <Modal show={showAddModal} onHide={handleCloseAddModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Record</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control type="text" name="firstName" value={newRow.firstName} onChange={handleInputChange} />
            </Form.Group>

            <Form.Group controlId="formLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control type="text" name="lastName" value={newRow.lastName} onChange={handleInputChange} />
            </Form.Group>

            <Form.Group controlId="formAge">
              <Form.Label>Age</Form.Label>
              <Form.Control type="number" name="age" value={newRow.age} onChange={handleInputChange} />
            </Form.Group>

            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" name="email" value={newRow.email} onChange={handleInputChange} />
            </Form.Group>

            <Form.Group controlId="formSalary">
              <Form.Label>Salary</Form.Label>
              <Form.Control type="number" name="salary" value={newRow.salary} onChange={handleInputChange} />
            </Form.Group>

            <Form.Group controlId="formDepartment">
              <Form.Label>Department</Form.Label>
              <Form.Control type="text" name="department" value={newRow.department} onChange={handleInputChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAddModal}>Close</Button>
          <Button variant="primary" onClick={handleAddRow}>Add</Button>
        </Modal.Footer>
      </Modal>


   <Row className="my-4">
        <Col>
          <Card>
            <Card.Header>
              <h3>Menu with Submenus</h3>
            </Card.Header>
            <Card.Body>
              <div className="d-flex justify-content-around">
                {/* First Menu */}
                <DropdownButton id="dropdown-menu1" title="Menu 1">
                  <Dropdown.Item eventKey="action1">Action 1</Dropdown.Item>
                  <Dropdown.Item eventKey="action2">Action 2</Dropdown.Item>
                </DropdownButton>

                {/* Second Menu */}
                <DropdownButton id="dropdown-menu2" title="Menu 2">
                  <Dropdown.Item eventKey="subaction1">Sub Action 1</Dropdown.Item>
                  <Dropdown.Item eventKey="subaction2">Sub Action 2</Dropdown.Item>
                </DropdownButton>

                {/* Third Menu with Submenus */}
                <DropdownButton id="dropdown-menu3" title="Menu 3">
                  <Dropdown.Item eventKey="subaction1">Sub Action 1</Dropdown.Item>
                  <Dropdown.Item eventKey="subaction2">Sub Action 2</Dropdown.Item>

                  {/* Sub Action 3 with Nested Submenu on Hover */}
                  <div
                    className="dropdown-item"
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                    style={{ position: 'relative' }}
                  >
                    Sub Action 3
                    {hovered && (
                      <div
                        style={{
                          display: 'block',
                          position: 'absolute',
                          left: '100%',
                          top: '0',
                          backgroundColor: 'white',
                          border: '1px solid #ccc',
                          zIndex: 1000
                        }}
                      >
                        <Dropdown.Item eventKey="nestedaction1">Nested Action 1</Dropdown.Item>
                        <Dropdown.Item eventKey="nestedaction2">Nested Action 2</Dropdown.Item>
                        <Dropdown.Item eventKey="nestedaction3" onClick={handleNestedSubmenuClick}>
                          Open Google
                        </Dropdown.Item>
                      </div>
                    )}
                  </div>

                  <Dropdown.Item eventKey="subaction4">Sub Action 4</Dropdown.Item>
                </DropdownButton>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>


      <Row className="my-4">
        <Col>
          <Card>
            <Card.Header>
              <h3>Drag and Drop Testing</h3>
            </Card.Header>
            <Card.Body>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* Draggable Items */}
                <div
                  style={{
                    width: '45%',
                    padding: '10px',
                    border: '1px solid #ccc',
                    minHeight: '200px',
                    backgroundColor: '#f9f9f9',
                  }}
                >
                  <h5>Draggable Items</h5>
                  {draggableItems.map((item) => (
                    <div
                      key={item.id}
                      draggable
                      onDragStart={() => handleDragStart(item)}
                      style={{
                        padding: '8px',
                        margin: '4px 0',
                        border: '1px solid #aaa',
                        cursor: 'grab',
                        backgroundColor: '#ffffff',
                      }}
                    >
                      {item.label}
                    </div>
                  ))}
                </div>

                {/* Drop Zone */}
                <div
                  style={{
                    width: '45%',
                    padding: '10px',
                    border: '1px solid #ccc',
                    minHeight: '200px',
                    backgroundColor: '#f0f8ff',
                  }}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                >
                  <h5>Drop Zone</h5>
                  {dropZoneItems.length === 0 ? (
                    <p>Drop items here</p>
                  ) : (
                    dropZoneItems.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          padding: '8px',
                          margin: '4px 0',
                          border: '1px solid #aaa',
                          backgroundColor: '#e0e0e0',
                        }}
                      >
                        {item.label}
                      </div>
                    ))
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </Container>
  );
};

export default TestingSelenium;
