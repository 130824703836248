// src/components/Video.js
import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { videoData } from '../data/videoData';
// import { videoData } from './videoData';


const Video = () => {
  return (
    <div className="my-4">
      <h2>Video Library</h2>
      <Row>
        {videoData.map((video) => (
          <Col md={4} key={video.id} className="mb-4">
            <Card>
              <Card.Body>
                <Card.Title>{video.title}</Card.Title>
                <div className="ratio ratio-16x9">
                  <iframe
                    src={video.url}
                    title={video.title}
                    allowFullScreen
                    frameBorder="0"
                  ></iframe>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Video;
