import UntitledES6 from "../../src/images/UntitledES6.png";
import ccc from "../../src/images/ccc.png";


const javascriptCourses = [
    {
      title: "JavaScript ES6",
      image: ccc,
      link: "https://www.udemy.com/course/mastering-javascript-es6-es7-and-es8-zero-to-hero-2024/?referralCode=471987A39A5A14FAAB5A"
    },
    {
      title: "Interview JavaScript preparation",
      image: UntitledES6,
      link: "https://www.udemy.com/course/javascript-interview-questions-basics-to-advanced-2024/?referralCode=177689BA75481B7ADC4F"
    },
  ];
  
  export default javascriptCourses;
  