const hackingData = [
  {
    title: "🤤 How to Become an Ethical Hacker in 8 Months  ",
    content: `
  1.   Start from the Basics (Month 1)
-Basic Computer Skills
-Intro to Cybersecurity
-CIA Triads
-Intro to Ethical Hacking
-PenTesting
-Phases of Ethical Hacking.
2.  Learn Networking Concepts (Month 2)
-Network Basics
-IP and MAC Address 
-Ports
-Topology
-OSI Model
-TCP and UDP
3.   Learn some Programming Languages (Month 3 & 4)
-Python
-JavaScript
-HTML
-Shell Scripting 
4. Database Skills (Month 5)
-SQL
5.  Get Hands-on Experience (Month 6 &7)
-Get well versed on Kali Linux
-Practice on Platforms like TryHackMe and HackTheBox e.t.c.
6.  Explore other Cybersecurity Techniques (Month 8)
-Password Cracking
-WI-FI Hacking
-Steganography
-Web Hacking
-Social Engineering
-Dark Web
-Google Dorking`
  },
  


    {
        title: "100 web Vulnerabilities, categorized into various types : 💻",
        content: `Injection Vulnerabilities:
1. SQL Injection (SQLi)
2. Cross-Site Scripting (XSS)
3. Cross-Site Request Forgery (CSRF)
4. Remote Code Execution (RCE)
5. Command Injection
6. XML Injection
7. LDAP Injection
8. XPath Injection
9. HTML Injection
10. Server-Side Includes (SSI) Injection
11. OS Command Injection
12. Blind SQL Injection
13. Server-Side Template Injection (SSTI)

💻 Broken Authentication and Session Management:
14. Session Fixation
15. Brute Force Attack
16. Session Hijacking
17. Password Cracking
18. Weak Password Storage
19. Insecure Authentication
20. Cookie Theft
21. Credential Reuse

🐱 Sensitive Data Exposure:
22. Inadequate Encryption
23. Insecure Direct Object References (IDOR)
24. Data Leakage
25. Unencrypted Data Storage
26. Missing Security Headers
27. Insecure File Handling

💻 Security Misconfiguration:
28. Default Passwords
29. Directory Listing
30. Unprotected API Endpoints
31. Open Ports and Services
32. Improper Access Controls
33. Information Disclosure
34. Unpatched Software
35. Misconfigured CORS
36. HTTP Security Headers Misconfiguration

🔹 XML-Related Vulnerabilities:
37. XML External Entity (XXE) Injection
38. XML Entity Expansion (XEE)
39. XML Bomb

🐱 Broken Access Control:
40. Inadequate Authorization
41. Privilege Escalation
42. Insecure Direct Object References
43. Forceful Browsing
44. Missing Function-Level Access Control

💻 Insecure Deserialization:
45. Remote Code Execution via Deserialization
46. Data Tampering
47. Object Injection

🔹 API Security Issues:
48. Insecure API Endpoints
49. API Key Exposure
50. Lack of Rate Limiting
51. Inadequate Input Validation

💻 Insecure Communication:
52. Man-in-the-Middle (MITM) Attack
53. Insufficient Transport Layer Security
54. Insecure SSL/TLS Configuration
55. Insecure Communication Protocols

🔸 Client-Side Vulnerabilities:
56. DOM-based XSS
57. Insecure Cross-Origin Communication
58. Browser Cache Poisoning
59. Clickjacking
60. HTML5 Security Issues

🐱 Denial of Service (DoS):
61. Distributed Denial of Service (DDoS)
62. Application Layer DoS
63. Resource Exhaustion
64. Slowloris Attack
65. XML Denial of Service

🔹 Other Web Vulnerabilities:
66. Server-Side Request Forgery (SSRF)
67. HTTP Parameter Pollution (HPP)
68. Insecure Redirects and Forwards
69. File Inclusion Vulnerabilities
70. Security Header Bypass
71. Clickjacking
72. Inadequate Session Timeout
73. Insufficient Logging and Monitoring
74. Business Logic Vulnerabilities
75. API Abuse

💻 Mobile Web Vulnerabilities:
76. Insecure Data Storage on Mobile Devices
77. Insecure Data Transmission on Mobile Devices
78. Insecure Mobile API Endpoints
79. Mobile App Reverse Engineering

🔸 IoT Web Vulnerabilities:
80. Insecure IoT Device Management
81. Weak Authentication on IoT Devices
82. IoT Device Vulnerabilities

💻 Web of Things (WoT) Vulnerabilities:
83. Unauthorized Access to Smart Homes
84. IoT Data Privacy Issues

🔹 Authentication Bypass:
85. Insecure "Remember Me" Functionality
86. CAPTCHA Bypass

🔸 Server-Side Request Forgery (SSRF):
87. Blind SSR
88. Time-Based Blind SSRF

💻 Content Spoofing:
89. MIME Sniffing
90. X-Content-Type-Options Bypass
91. Content Security Policy (CSP) Bypass

🔸 Business Logic Flaws:
92. Inconsistent Validation
93. Race Conditions
94. Order Processing Vulnerabilities
95. Price Manipulation
96. Account Enumeration
97. User-Based Flaws

🔹 Zero-Day Vulnerabilities:
98. Unknown Vulnerabilities
99. Unpatched Vulnerabilities
100. Day-Zero Exploits.`
      },
    {
      title: "🎙Top 10 Common Hacking Techniques All Begginers Should Know About. 🎙",
      content: ` Unethical hacking can be called an illegal activity to get unauthorized information by modifying a system’s features and exploiting its loopholes. In this world where most of the things happen online, hacking provides wider opportunities for the hackers to gain unauthorized access to the unclassified information like credit card details, email account details, and other personal information.
So, it is also important to know some of the hacking techniques that are commonly used to get your personal information in an unauthorized way.


1. Bait and switch⭐️
Using bait and switch hacking technique, the hacker runs a malicious program which the user believes to be authentic. This way, after installing the malicious program on your computer, the hacker gets unprivileged access to your computer.

2. Cookie theft⭐️
The cookies of a browser keep our personal data such as browsing history, username, and passwords for different sites that we access. Once the hacker gets the access to your cookie, he can even authenticate himself as you on a browser.

3. Clickjacking Attacks⭐️
Clickjacking is also known by a different name, UI Redress. In this attack, the hacker hides the actual UI where the victim is supposed to click.

4. Denial of Service (DoS/DoS)⭐️
A Denial of Service attack is a hacking technique to take down a site or server by flooding that site or server with a lot of traffic that the server is unable to process all the requests in the real time and finally crashes down.
For DDoS attacks, hackers often deploy botnets or zombie computers which have got the only work to flood your system with request packets.

5. Waterhole attacks➕
If you are a big fan of Discovery or National Geographic channels, you could relate easily with the waterhole attacks. To poison a place, in this case, the hacker hits the most accessible physical point of the victim.
For example, if the source of a river is poisoned, it will hit the entire stretch of animals during summer. In the same way, hackers target the most accessed physical location to attack the victim. That point could be a coffee shop, a cafeteria etc.
Once hackers are aware of your timings, they might create a fake Wi-Fi access point and modify your most visited website to redirect them to you to get your personal information.

6. Fake WAP⭐️
Even just for fun, a hacker can use software to fake a wireless access point. This WAP connects to the official public place WAP. Once you get connected the fake WAP, a hacker can access your data, just like in the above case.

7. Eavesdropping (Passive Attacks)⭐️
Unlike other attacks which are active in nature, using a passive attack, a hacker just monitors the computer systems and networks to gain some unwanted information.
The motive behind eavesdropping is not to harm the system but to get some information without being identified.

8. Phishing⭐️
Phishing is a hacking technique using which a hacker replicates the most-accessed sites and traps the victim by sending that spoofed link.
Once the victim tries to login or enter some data, the hacker gets that private information of the target victim using the trojan running on the fake site.

9. Virus, Trojan etc.⭐️
Virus or trojans are malicious software programs which get installed into the victim’s system and keeps sending the victims data to the hacker.
In another word, the attacker hijacks the clicks of the victim that aren’t meant for the exact page, but for a page where the hacker wants you to be

10. Keylogger⭐️
Keylogger is a simple software that records the key sequence and strokes of your keyboard into a log file on your machine. These log files might even contain your personal email IDs and passwords.
Keylogger is one of the main reasons why online banking sites give you an option to use their virtual keyboards.



`
    },
    {
      title: "18 web-application hacking tools",
      content: `
      1. Burp Suite - Framework.
2. ZAP Proxy - Framework.
3. Dirsearch - HTTP bruteforcing.
4. Nmap - Port scanning.
5. Sublist3r - Subdomain discovery.
6. Amass - Subdomain discovery.
7. SQLmap - SQLi exploitation.
8. Metasploit - Framework.
9. WPscan - WordPress exploitation.
10. Nikto - Webserver scanning.
11. HTTPX - HTTP probing.
12. Nuclei - YAML based template scanning.
13. FFUF - HTTP probing.
14. Subfinder - Subdomain discovery.
15. Masscan - Mass IP and port scanner.
16. Lazy Recon - Subdomain discovery.
17. XSS Hunter - Blind XSS discovery.
18. Aquatone - HTTP based recon.
      
      `
    },
    {
        title: " Phishing, Vishing and SMShing",
        content: `Phishing:-
Works by sending fake emails that look real by impersonating trustworthy sources to trick people into sharing sensitive info. 

Vishing:-
 when scammers call you on the phone where attackers use voice calls to deceive victims

SMShing:-
targets through text messages, leading users to malicious sites to steal personal information,bank logs ,ccs (like in sms spamming`
      },   
      
      {
        title: "👨‍💻 40 Commonly Targeted Ports by Programmer",
        content: ` 🔒 Port 21 (FTP)
    🚪 Port 22 (SSH)
    💻 Port 23 (Telnet)
    📧 Port 25 (SMTP)
    🌐 Port 53 (DNS)
    🌐 Port 80 (HTTP)
    🔒 Port 443 (HTTPS)
    🎮 Port 3074 (Xbox Live)
    📲 Port 5060 (SIP)
    🎲 Port 8080 (Proxy)
    📁 Port 135 (RPC)
    🖥 Port 139 (NetBIOS)
    🔓 Port 1433 (MSSQL)
    🎲 Port 1521 (Oracle)
    🔓 Port 1723 (PPTP)
    📤 Port 1900 (UPnP)
    🎮 Port 2302 (DayZ)
    🖨 Port 3389 (RDP)
    🔒 Port 3306 (MySQL)
    🕸 Port 4000 (Elasticsearch)
    📂 Port 4444 (Metasploit)
    📤 Port 5000 (Python Flask)
    🎮 Port 5555 (Android Debug Bridge)
    📤 Port 5900 (VNC)
    🖥 Port 6667 (IRC)
    📧 Port 6697 (IRC SSL)
    📂 Port 8000 (HTTP Alt)
    🖥 Port 8081 (HTTP Proxy)
    🔓 Port 9100 (Printer)
    📂 Port 9090 (Web Debugging)
    📁 Port 445 (SMB)
    💻 Ports 5985/5986 (WinRM)
    🔄 Port 6379 (Redis)
    📂 Port 6666 (IRC)
    📧 Port 993 (IMAP SSL)
    🔒 Port 995 (POP3 SSL)
    🎲 Port 1434 (Microsoft SQL Monitor)
    📂 Port 27017 (MongoDB)
    🌐 Port 28017 (MongoDB HTTP Interface)`
      }, 
      
      
      {
        title: "30 Tips how to use OSINT for bug hunting: ",
        content:`1. Use Google Dorks to find vulnerabilities in web applications.
2. Use Shodan to find vulnerable IoT devices.
3. Use Whois to find information about domain names.
4. Use Maltego to visualize relationships between entities.
5. Use the Wayback Machine to find old versions of websites.
6. Use social media to gather information about targets.
7. Use LinkedIn to gather information about employees.
8. Use GitHub to find sensitive information in code repositories.
9. Use Google Alerts to monitor for mentions of your target.
10. Use DNSDumpster to map out a target's infrastructure.
11. Use Recon-ng to automate OSINT tasks.
12. Use theHarvester to gather email addresses and other information.
13. Use SpiderFoot to automate OSINT tasks and gather intelligence.
14. Use FOCA (Fingerprinting Organizations with Collected Archives) to gather metadata from documents.
15. Use VirusTotal to scan files for malware.
16. Use Censys to find vulnerable systems on the internet.
17. Use Foca Pro to extract metadata from documents and analyze it.
18. Use FOCA Online to extract metadata from documents and analyze it in the cloud.
19. Use FOCA Free Edition for basic metadata extraction from documents.
20. Use Metagoofil to extract metadata from documents and analyze it.
21. Use Datasploit for automated OSINT tasks and data mining.
22. Use Google Hacking Database (GHDB) for advanced Google searches.
23. Use Google Custom Search Engine (CSE) for targeted searches on specific websites or domains.
24. Use Google Advanced Search for advanced searches on Google.
25. Use Google Trends to monitor trends related to your target or industry.
26. Use Google Analytics to gather information about website traffic and user behavior.
27. Use Google AdWords Keyword Planner for keyword research related to your target or industry.
28. Use Google PageSpeed Insights to analyze website performance and identify vulnerabilities.
29. Use Google Search Console (formerly Webmaster Tools) for website analytics and vulnerability identification.
30. Use Google My Business for local SEO optimization.`
      },
      
      {
        title: "🐰how to become anonymous🤩",
        content:`Use a privacy-focused operating system, such as Tails, that routes all internet traffic through the Tor network.

 Use a VPN or TOR to mask your IP address and encrypt your internet traffic.

 Use a pseudonym or alias instead of your real name.

 Avoid using personal information, such as your home address or date of birth, online.

 Use a secure web browser, such as Tor,Brave,StartPage that protects your anonymity and disable webrtc

 Avoid using your personal email address for online activities.use fake email for anonymous activities

 Avoid sharing photos or location data on social media.

 Use two-factor authentication whenever possible to add an extra layer of security to your online accounts.

 Clear your cookies and browsing history regularly to prevent tracking.

 Avoid using the same username across multiple websites.

 control your needs because your needs and your emotion toward  girl makes you  vulnerable💐❤️

 Avoid using your personal phone number for online accounts. they can track you easily with this Instead, use a virtual phone number.

 Be aware of phishing scams and other tactics like honey trap that attackers can use to trick you into revealing personal information

 Use a secure file-sharing service, such as SpiderOak, that encrypts your files and offers secure storage.

 Trust your instincts and be cautious of anyone who asks for personal information or seems suspicious
`
      }, 
      
      
      
      {
        title: "Information Gathering Tools",
        content: `
ace-voip ,               
 goofile
dnswalk
ident-user-enum
Nmap 
Sublist3r 
bing-ip2hosts
DotDotPwn
smtp-user-enum 

 Xplico
SPARTA
Phisher`
      }, 
      
      
      {
        title: "20 Top Recon Tools For Ethical Hackers ",
        content: `>Nmap
>Maltego
>Gau 
>Subfinder
>Dirsearch 
>Amass
>Gobuster 
>Feroxbuster
>Gowitness
>Altdns
>Rustscan
>Waymore
>Gospider
>NAABU
>Masscan
>Gotator
>FFUF
>DnsValidator
>WhatWeb
>Assetfinder`
      },
      
      {
        title: "Useful Run Commands Every Windows User Should Know",
        content:`Press Win + R on your ⌨️ to open the Run dialog box and enter any of 👇 commands to access the respective tool.

🔹 "." -  the user's folder.
🔹 ".." - user folder.
🔹 "control" - control panel.
🔹 "msconfig" - system configuration parameters.
🔹 "appwiz.cpl" - programs and components.
🔹 "cleanmgr" - a disk cleaning utility.
🔹 "resmon" - resource monitor.
🔹 "calc", "notepad", "mspaint" - calculator, notepad and paint.
🔹 "main.cpl" - mouse parameters.
🔹 "mstsc" - remote desktop.
🔹 "msinfo32" - system information.
🔹 wab  - Contacts.
🔹 dccw - Display Color Calibration.
🔹 desk.cpl - Display Settings.`
      }, 
      
      {
        title: "💎18 Websites To Learn Linux For FREE💎",
        content:`
1. nixCraft 
2. Tecmint
3. Linuxize 
4. It’s FOSS
5. Linux Hint
6. LinuxOPsys
7. Linux Journey 
8. Linux Academy 
9. Linux Survival 
10. Linux Command
11. Ryan’s Tutorials
12. Linux Handbook
13. Linux FoundationX
14. LabEx Linux For Noobs
15. Guru99 Linux Tutorial Summary 
16. Conquering the command line
17. Intellipat Linux Tutorial for Beginners 
18. The Debian Administrators Handbook`
      }, 
      
      {
        title: "Myths and truth about hackers: ",
        content: `Myth 1 Hackers are always working alone.

Truth:-Hackers may operate alone or as part of a group, such as a hacking collective or criminal organization.

Myth 2 Hackers only want to steal confidential information.

Truth:-Hackers might also want to mess with systems or do damage, like by launching a denial-of-service attack.

Myth 3 Every hacker is very skilled and knows a lot about how computers work.

Truth:-Hackers can have different levels of skill and knowledge, ranging from simple hobbyists to very skilled professionals.

Myth 4 Hackers always try to be quiet and stay out of sight.

Truth:-Some hackers may care more about causing damage or trouble than about staying hidden.

Myth 5  It is easy to tell if a system has been hacked.

Truth: It may not always be easy to detect that a system has been hacked, especially if the hacker has taken steps to cover their tracks.

Myth 6 Hacker activity cannot be tracked

Truth:-The truth is that it is possible to track a hacker's actions in many cases, however doing so may require advanced tools and methods.

Myth 7 All hackers are very unethical and do things that are wrong in a moral sense.

Truth:-Some hackers may do things that are unethical or illegal, but that doesn't mean that all hackers do those things. Some hackers might want to make systems more secure or show where they are weak.

Myth 8 Hackers always leave traces of what they've done.

Truth:-Hackers may use methods to hide their tracks and make it hard to find out what they are doing.

Myth 9 Once a system has been hacked, it can never be made safe again.

Truth:-: A system that has been hacked can often be brought back to its original state by removing the malicious code and fixing any vulnerabilities 

Myth 10 Hackers can access anything. 

Truth:-Hackers can only access systems or data if they have the skills, resources, and vulnerabilities.

Myth 11 Anti-virus software is sufficient to prevent hacking.

Truth:-While antivirus software is an important component of a security plan, it is not a comprehensive solution. Other security precautions, such as firewalls and secure passwords, should also be used.

Myth 12 Hackers only target large companies or governments.

Truth: Hackers may target any organization or individual with valuable information or resources example telegram bug pelar😁😂😅 regardless of size.

Myth 13 All hackers are young, male, and enamored with computers..

Truth :-Hackers come from all walks of life and can be any age, gender, or country
`
      },
      
      {
        title: "Blocking any program from accessing the Internet in Windows",
        content: `If for some reason you need to block a certain program access to the Internet, it is relatively easy to do this with the help of the system's built-in functions. 

Windows Firewall is one of the easiest ways to block a program's access to the Internet that does not require the installation of any third-party tools. The steps for blocking will be as follows:

1️⃣ Press "Win+R" and enter the command "firewall.cpl"

2️⃣ On the left, click "Advanced Settings" and then "Rules for Outgoing Connection"

3️⃣ On the right side, click "Create Rule", select "For Program", specify the path, check "Block Connection", then "Next", specify "Name" and click "Done"`
      },
      
      {
        title: "⭕️ How to increase virtual RAM to make your computer system fast ⭕️",
        content:`1. Right-click on "My Computer", then select "Properties"

2. Click on the 'Advanced' tab

3. Under 'Performance', click on 'Settings'.

4. Then click on the "Advanced" tab on the button that appears

5. Under "Virtual Memory" at the bottom, click on "Edit".

6. Click on the 'Custom Size' button

7. For the initial size (depending on your HD space), you can type in 1000-1500 (although I use 4000), and for the maximum size you can type in 2000-2500 (although I use 6000)

8. Click on "Set", then exit all windows

9. Finally, restart your computer

10. You now have a faster computer and 1-6 GB (as specified by you in step 7) of virtual RAM. `
      }, 
      
      {
        title: "🧩Password Cracking Guide🧩",
        content: `Password cracking is one of the most commonly method used by hackers to crack passwords which involves using various computational and other methods to break through the password authentication step.
        🍀Windows Password Cracking🍀

        📝Create your own Hacking Lab in a Virtual Environment (Quick and Easy Way)

        📝Learn cracking Windows passwords
        
        📝How to bypass and reset Windows passwords?
        
        📝Learn practical use of the best password cracking tools including John, Ophcrack and Hashcat
        
        📝Learn the Basics of Windows Password storage and cracking
        
        📝Cracking passwords with wordlists and Rainbow tables
        using password cracking tools from Windows and Kali Linux
         
        📝How to Extract Windows passwords from RAM with Mimikatz
        📛Note📛
              Its only for educational purpose.`
      
      }, 
      {
        title: "CALL-BOMBER",
        content: `A Call Bomber is a tool or software that automatically sends a large volume of calls to a specific phone number in a short period.

Call bombers are usually used as a form of revenge, prank, or even cyberbullying. Call bombers work by using an auto-dialer to call a target number repeatedly, often hundreds of times per hour  `
      },
       {
        title: "Tips how to use OSINT for bug hunting: ",
        content: `1. Use Google Dorks to find vulnerabilities in web applications.
2. Use Shodan to find vulnerable IoT devices.
3. Use Whois to find information about domain names.
4. Use Maltego to visualize relationships between entities.
5. Use the Wayback Machine to find old versions of websites.
6. Use social media to gather information about targets.
7. Use LinkedIn to gather information about employees.
8. Use GitHub to find sensitive information in code repositories.
9. Use Google Alerts to monitor for mentions of your target.
10. Use DNSDumpster to map out a target's infrastructure.
11. Use Recon-ng to automate OSINT tasks.
12. Use theHarvester to gather email addresses and other information.
13. Use SpiderFoot to automate OSINT tasks and gather intelligence.
14. Use FOCA (Fingerprinting Organizations with Collected Archives) to gather metadata from documents.
15. Use VirusTotal to scan files for malware.
16. Use Censys to find vulnerable systems on the internet.
17. Use Foca Pro to extract metadata from documents and analyze it.
18. Use FOCA Online to extract metadata from documents and analyze it in the cloud.
19. Use FOCA Free Edition for basic metadata extraction from documents.
20. Use Metagoofil to extract metadata from documents and analyze it.
21. Use Datasploit for automated OSINT tasks and data mining.
22. Use Google Hacking Database (GHDB) for advanced Google searches.
23. Use Google Custom Search Engine (CSE) for targeted searches on specific websites or domains.
24. Use Google Advanced Search for advanced searches on Google.
25. Use Google Trends to monitor trends related to your target or industry.
26. Use Google Analytics to gather information about website traffic and user behavior.
27. Use Google AdWords Keyword Planner for keyword research related to your target or industry.
28. Use Google PageSpeed Insights to analyze website performance and identify vulnerabilities.
29. Use Google Search Console (formerly Webmaster Tools) for website analytics and vulnerability identification.
30. Use Google My Business for local SEO optimization.
`
      },
       {
        
        title: " Threat Hunting ",
        content: `Threat hunting is the proactive process of searching for hidden threats within a network or system. It involves using various techniques to identify and investigate potential security risks that traditional security measures might miss. Here are some of the best practices for effective threat hunting:

Hypothesis-driven hunting: 
This approach involves developing hypotheses about potential threats based on intelligence sources, and then using specific techniques to investigate those hypotheses.

Intelligence-led hunting: 
This method leverages threat intelligence feeds and indicators of compromise (IOCs) to identify and prioritize potential threats.

Custom hunting:
This approach involves creating custom queries and searches to look for specific indicators of malicious activity.

Utilizing threat hunting tools: 
Security professionals can leverage various tools to automate tasks, analyze data, and identify potential threats.

Collaboration and information sharing: 
Sharing threat hunting findings and collaborating with other security teams can help improve the overall effectiveness of threat hunting efforts.
`
      },
       {
        title: "18 web-application hacking tools:",
        content: `1. Burp Suite - Framework.
2. ZAP Proxy - Framework.
3. Dirsearch - HTTP bruteforcing.
4. Nmap - Port scanning.
5. Sublist3r - Subdomain discovery.
6. Amass - Subdomain discovery.
7. SQLmap - SQLi exploitation.
8. Metasploit - Framework.
9. WPscan - WordPress exploitation.
10. Nikto - Webserver scanning.
11. HTTPX - HTTP probing.
12. Nuclei - YAML based template scanning.
13. FFUF - HTTP probing.
14. Subfinder - Subdomain discovery.
15. Masscan - Mass IP and port scanner.
16. Lazy Recon - Subdomain discovery.
17. XSS Hunter - Blind XSS discovery.
18. Aquatone - HTTP based recon.`
      }, 
      {
        title: "Blocking any program from accessing the Internet in Windows",
        content: `If for some reason you need to block a certain program access to the Internet, it is relatively easy to do this with the help of the system's built-in functions. 

Windows Firewall is one of the easiest ways to block a program's access to the Internet that does not require the installation of any third-party tools. The steps for blocking will be as follows:

1️⃣ Press "Win+R" and enter the command "firewall.cpl"

2️⃣ On the left, click "Advanced Settings" and then "Rules for Outgoing Connection"

3️⃣ On the right side, click "Create Rule", select "For Program", specify the path, check "Block Connection", then "Next", specify "Name" and click "Done"`
      },
  ];
  
  export default hackingData;
  