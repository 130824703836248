import React from 'react';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import Header from './components/Header';
import CourseList from './components/CourseList';
import Footer from './components/Footer';
// import Materials from './components/Materials';
import HackingBlog from './components/HackingBlog';
import ArticleDetail from './components/ArticleDetail';
import HomePage from './components/HomePage';
// import QuizData from './data/QuizData';
import QuizHacking from './quiz/QuizHacking';
import QuizJavaScript from './quiz/QuizJavaScript';
import QuizAI from './quiz/QuizAI';
import QuizReact from './quiz/QuizReact';
import NotFound from './components/NotFound ';
import Articletooldetail from './components/Articletooldetail';
import SecretToolsBlog from './components/SecretToolsBlog';
import TestingSelenium from './components/TestingSelenium';
// import Joke from './components/Joke';

// Map topics to components

function App() {

  const quizComponents = {
    react: QuizReact,
    hacking: QuizHacking,
    javascript: QuizJavaScript,
    ai: QuizAI,
  };

  const QuizData = () => {
    const { topicName } = useParams();
    const QuizComponent = quizComponents[topicName];
    return QuizComponent ? <QuizComponent /> : <NotFound/>;
  };


  return (
    <Router>
      <>
        <Header />
        <div className="container mt-5 pt-5">
          {/* AdSense Space */}
          <div className="my-4 text-center">
            <img src="https://dummyimage.com/728x90/ccc/000&text=Google+Ad" alt="Google Ad" className="img-fluid" />
          </div>
        </div>
        <Routes>
        <Route path="/" element={<HomePage />} />

        {/* <Route path="/quiz/react" element={<QuizReact />} />
  <Route path="/quiz/hacking" element={<QuizHacking />} />
  <Route path="/quiz/javascript" element={<QuizJavaScript />} />
  <Route path="/quiz/ai" element={<QuizAI />} /> */}
<Route path="/quiz/:topicName" element={<QuizData />} />
      <Route path="/courselist" element={<CourseList />} />
          {/* <Route path="/materials" element={<Materials />} /> */}
          <Route path="/hacking-blog" element={<HackingBlog />} />
          <Route path="/hacking-blog/:id" element={<ArticleDetail />} />
          <Route path="/techtools-blog" element={<SecretToolsBlog />} />
          <Route path="/techtools-blog/:id" element={<Articletooldetail />} />
          {/* <Route path="/couress" element={<Joke />} /> */}
          <Route path="/testing" element={<TestingSelenium />} />

        </Routes>
        <Footer />
      </>
    </Router>
  );
}

export default App;
