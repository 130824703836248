import React from 'react';
import { Link } from 'react-router-dom';

//IN HADERR JS WE WILL ADD ALL NAV ITEMS HERE AND LINK TAG
const Header = () => {
  return (
    <header className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">Home</Link>
        <div className="collapse navbar-collapse">
          <ul className="navbar-nav me-auto">

            <li className="nav-item"><Link className="nav-link" to="/courselist">Courses</Link></li>
            {/* <li className="nav-item"><Link className="nav-link" to="/materials">Materials</Link></li> */}
            <li className="nav-item"><Link className="nav-link" to="/hacking-blog">Hacking Blog</Link></li>
            <li className="nav-item"><Link className="nav-link" to="/techtools-blog">Tech Blog</Link></li>   
            {/* <li className="nav-item"><Link className="nav-link" to="/couress">Joke Blog</Link></li>  */}
            {/* <li className="nav-item"><Link className="nav-link" to="/couress">phyton Blog</Link></li>          */}
            <li className="nav-item"><Link className="nav-link" to="/testing">Testing</Link></li> 
          </ul>
          <form className="d-flex">
            <input className="form-control me-2" type="search" placeholder="Search" />
            <button className="btn btn-outline-light" type="submit">Search</button>
          </form>
        </div>
      </div>
    </header>
  );
};

export default Header;
