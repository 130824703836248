const pythonCourses = [
    {
      title: "Python for Data Science",
      image: "https://dummyimage.com/200x150/000/fff&text=Python1",
      link: "https://www.udemy.com/course/fundamentos-de-programacion-en-cjavacphyton-y-javascript/",
      Category: "Development",
      Price: "Free",

    },
    {
      title: "Python Machine Learning",
      image: "https://dummyimage.com/200x150/000/fff&text=Python2",
      link: "https://example.com/python-course-2",
      Category: "Development",
      Price: "Free",
    },
   
  ];
  
  export default pythonCourses;

// src/data/pythonCourses.js

// import { useState, useEffect } from 'react';

// const usePythonCourses = () => {
//   const [courses, setCourses] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchCourses = async () => {
//       try {
//         const response = await fetch("https://udemy-paid-courses-for-free-api.p.rapidapi.com/rapidapi/courses/search?page=1&page_size=18&query=python", {
//           method: "GET",
//           headers: {
//             "X-RapidAPI-Key": process.env.REACT_APP_RAPIDAPI_KEY,
//             "X-RapidAPI-Host": process.env.REACT_APP_RAPIDAPI_HOST,
//           },  
//         });
//         const data = await response.json();
//         //  console.log("Fetched courses data:", data); 
//         setCourses(data.courses || []);
//       } catch (err) {
//         setError(err);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchCourses();
//   }, []);

//   return { courses, loading, error };
// };

// export default usePythonCourses;
