import React, { useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import TestCompleteModal from '../components/TestCompleteModal';

const jsQuestions = [
  { id: 1, question: 'What is JavaScript?', options: ['Programming language', 'Markup language', 'Style sheet', 'None'], answer: 'Programming language' },
  { id: 2, question: 'Which company developed JavaScript?', options: ['Netscape', 'Google', 'Microsoft', 'Apple'], answer: 'Netscape' },
  // Add more questions here
];

const QuizJavaScript = () => {
  const [answers, setAnswers] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [score, setScore] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleAnswerChange = (e, questionId) => {
    setAnswers({ ...answers, [questionId]: e.target.value });
  };

  const handleSubmit = () => {
    let calculatedScore = 0;
    jsQuestions.forEach((q) => {
      if (answers[q.id] === q.answer) calculatedScore += 1;
    });
    setScore(calculatedScore);
    setSubmitted(true);
    setShowModal(true);
  };

  return (
    <Container className="mt-5">
      <h2>JavaScript Quiz</h2>
      <Form>
        {jsQuestions.map((question) => (
          <div key={question.id} className="mb-4">
            <h5>{question.question}</h5>
            {question.options.map((option, idx) => (
              <Form.Check
                type="radio"
                key={idx}
                label={option}
                name={`question-${question.id}`}
                value={option}
                onChange={(e) => handleAnswerChange(e, question.id)}
                disabled={submitted}
              />
            ))}
          </div>
        ))}
        <Button className="mt-3" onClick={handleSubmit} disabled={submitted}>
          Submit
        </Button>
        <Button className="mt-3 ms-2" onClick={() => navigate('/')}>
          Back to Home
        </Button>
      </Form>

      <TestCompleteModal show={showModal} score={score} total={jsQuestions.length} onClose={() => setShowModal(false)} answers={answers} questions={jsQuestions} />
    </Container>
  );
};

export default QuizJavaScript;
