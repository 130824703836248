// src/components/videoData.js
export const videoData = [
    { id: 1, title: 'Video 1', url: 'https://www.youtube.com/embed/dQw4w9WgXcQ' },
    { id: 2, title: 'Video 2', url: 'https://www.youtube.com/embed/dQw4w9WgXcQ' },
    { id: 3, title: 'Video 3', url: 'https://www.youtube.com/embed/dQw4w9WgXcQ' },
    { id: 4, title: 'Video 4', url: 'https://www.youtube.com/embed/dQw4w9WgXcQ' },
    { id: 5, title: 'Video 5', url: 'https://www.youtube.com/embed/dQw4w9WgXcQ' },
    { id: 6, title: 'Video 6', url: 'https://www.youtube.com/embed/dQw4w9WgXcQ' },
    { id: 7, title: 'Video 7', url: 'https://www.youtube.com/embed/dQw4w9WgXcQ' },
    { id: 8, title: 'Video 8', url: 'https://www.youtube.com/embed/dQw4w9WgXcQ' },
    { id: 9, title: 'Video 9', url: 'https://www.youtube.com/embed/dQw4w9WgXcQ' },
  ];
  