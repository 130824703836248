import React, { useState } from 'react';
// import SecretToolsData from '../data/SecretToolsData'; // Adjust path as needed
import SecretToolsData  from '../data/SecretToolsData';
import { Link } from 'react-router-dom';

// Function to convert newline characters to HTML line breaks
const formatContent = (content) => {
  return content.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));
};

const SecretToolsBlog = () => {
  const articlesPerPage = 9; // Show 9 articles per page
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(SecretToolsData.length / articlesPerPage);
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = SecretToolsData.slice(indexOfFirstArticle, indexOfLastArticle);

  const handleClick = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container mt-5 pt-5">
      <h1 className="text-center my-4">TECH TOOLS-SECRET</h1>
      <div className="row justify-content-between">
        {currentArticles.map((article, index) => (
          <div key={index} className="col-md-4 mb-4 d-flex">
            <div className="card flex-fill h-100 shadow-sm">
              <div className="card-body">
                <h5 className="card-title">{article.title}</h5>
                <div className="card-text">
                  {article.content.length > 300
                    ? formatContent(article.content.substring(0, 300) + '...')
                    : formatContent(article.content)}
                </div>
                {article.content.length > 300 && (
                  <Link 
                    to={`/techtools-blog/${indexOfFirstArticle + index}`} // Correct index calculation
                    className="btn btn-primary"
                  >
                    Read More
                  </Link>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination */}
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-center">
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handleClick(currentPage - 1)}>Previous</button>
          </li>
          {[...Array(totalPages)].map((_, index) => (
            <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
              <button className="page-link" onClick={() => handleClick(index + 1)}>{index + 1}</button>
            </li>
          ))}
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handleClick(currentPage + 1)}>Next</button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default SecretToolsBlog;
