import React, { useState } from 'react';
import CourseCard from './CourseCard';
import pythonCourses from '../data/pythonCourses';
import javascriptCourses from '../data/javascriptCourses';
import seleniumCourses from '../data/seleniumCourses';

const CourseList = () => {
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [searchQuery, setSearchQuery] = useState('');

  const filterCourses = (courses) => {
    return Array.isArray(courses) ? 
      courses.filter((course) =>
        course.title.toLowerCase().includes(searchQuery.toLowerCase())
      ) : [];
  };

  let coursesToShow = [];
  if (selectedCategory === 'All') {
    coursesToShow = [
      ...filterCourses(pythonCourses),
      ...filterCourses(javascriptCourses),
      ...filterCourses(seleniumCourses),
    ];
  } else if (selectedCategory === 'Python') {
    coursesToShow = filterCourses(pythonCourses);
  } else if (selectedCategory === 'JavaScript') {
    coursesToShow = filterCourses(javascriptCourses);
  } else if (selectedCategory === 'Selenium') {
    coursesToShow = filterCourses(seleniumCourses);
  }

  return (
    <div className="container mt-5 pt-5">
      <h2>Top Courses Available Now:</h2>
      <div className="mb-4">
        <button className="btn btn-outline-primary me-2" onClick={() => setSelectedCategory('All')}>All</button>
        <button className="btn btn-outline-primary me-2" onClick={() => setSelectedCategory('Python')}>Python</button>
        <button className="btn btn-outline-primary me-2" onClick={() => setSelectedCategory('JavaScript')}>JavaScript</button>
        <button className="btn btn-outline-primary me-2" onClick={() => setSelectedCategory('Selenium')}>Selenium</button>
        <input
          className="form-control d-inline-block w-25"
          type="text"
          placeholder="Search courses..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <div className="row">
        {coursesToShow.map((course, index) => (
          <CourseCard key={index} course={course} />
        ))}
      </div>
    </div>
  );
};

export default CourseList;

//real api implemnentation
// import React, { useState } from 'react';
// import CourseCard from './CourseCard';
// import usePythonCourses from '../data/pythonCourses';
// import javascriptCourses from '../data/javascriptCourses';
// import seleniumCourses from '../data/seleniumCourses';

// const CourseList = () => {
//   const { courses: pythonCourses, loading: pythonLoading, error: pythonError } = usePythonCourses();
//   const [selectedCategory, setSelectedCategory] = useState('All');
//   const [searchQuery, setSearchQuery] = useState('');

//   const filterCourses = (courses) => {
//     return Array.isArray(courses) ? 
//       courses.filter((course) =>
//         (course.title || "").toLowerCase().includes(searchQuery.toLowerCase())
//       ) : [];
//   };

//   let coursesToShow = [];
//   if (selectedCategory === 'All') {
//     coursesToShow = [
//       ...filterCourses(pythonCourses),
//       ...filterCourses(javascriptCourses),
//       ...filterCourses(seleniumCourses),
//     ];
//   } else if (selectedCategory === 'Python') {
//     coursesToShow = filterCourses(pythonCourses);
//   } else if (selectedCategory === 'JavaScript') {
//     coursesToShow = filterCourses(javascriptCourses);
//   } else if (selectedCategory === 'Selenium') {
//     coursesToShow = filterCourses(seleniumCourses);
//   }

//   if (pythonLoading) {
//     return <p>Loading Python courses...</p>;
//   }

//   if (pythonError) {
//     return <p>Error loading Python courses.</p>;
//   }

//   return (
//     <div className="container mt-5 pt-5">
//       <h2>Top Courses Available Now:</h2>
//       <div className="mb-4">
//         <button className="btn btn-outline-primary me-2" onClick={() => setSelectedCategory('All')}>All</button>
//         <button className="btn btn-outline-primary me-2" onClick={() => setSelectedCategory('Python')}>Python</button>
//         <button className="btn btn-outline-primary me-2" onClick={() => setSelectedCategory('JavaScript')}>JavaScript</button>
//         <button className="btn btn-outline-primary me-2" onClick={() => setSelectedCategory('Selenium')}>Selenium</button>
//         <input
//           className="form-control d-inline-block w-25"
//           type="text"
//           placeholder="Search courses..."
//           value={searchQuery}
//           onChange={(e) => setSearchQuery(e.target.value)}
//         />
//       </div>
//       <div className="row">
//         {coursesToShow.map((course, index) => (
//           <CourseCard key={index} course={course} />
//         ))}
//       </div>
//     </div>  
//   );
// };

// export default CourseList;


