import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const TestCompleteModal = ({ show, score, total, onClose, answers, questions }) => {
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Quiz Completed</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Congratulations! You completed the quiz successfully.</p>
        <p>Your Score: {total}/{score}</p>

        <h5>Review Your Answers:</h5>
        {questions.map((question) => (
          <div key={question.id}>
            <p><strong>Q:</strong> {question.question}</p>
            <p><strong>Your Answer:</strong> {answers[question.id] || 'Not Answered'}</p>
            <p><strong>Correct Answer:</strong> {question.answer}</p>
            <hr />
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onClose}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TestCompleteModal;
