import React from 'react';

const CourseCard = ({ course }) => {
  return (
    <div className="col-lg-4 col-md-6 mb-4"> {/* Adjusted column size to limit to 3 per row */}
      <div className="card shadow">
        <img src={course.image} className="card-img-top" alt={course.title} />
        <div className="card-body">
          <h5 className="card-title">{course.title}</h5>
     


          <a
            href={course.link}
            className="btn btn-primary"
            target="_blank"
            rel="noopener noreferrer"
          >
            Start Course
          </a>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;


// const CourseCard = ({ course }) => {
//   const {
//     name,
//     category,
//     image,
//     actual_price_usd,
//     sale_price_usd,
//     sale_end,
//     description
//   } = course;

//   return (
//     <div className="col-lg-4 col-md-6 mb-4">
//       <div className="card shadow">
//         <img src={image} className="card-img-top" alt={name} />
//         <div className="card-body">
//           <h5 className="card-title">{name}</h5>
//           {/* <p className="card-text">{description}</p> */}
//           <p className="card-text">
//             {/* <strong>Category:</strong> {category} */}
//           </p>
//           <p className="card-text">
//             <strong>Price:</strong> ${sale_price_usd === 0 ? 'Free' : sale_price_usd}
//             {actual_price_usd > sale_price_usd && (
//               <span className="text-muted ms-2">
//                 <del>${actual_price_usd}</del>
//               </span>
//             )}
//           </p>
//           <p className="card-text">
//             {sale_end && <small className="text-muted">Sale ends on: {new Date(sale_end).toLocaleDateString()}</small>}
//           </p>
//           <a
//             href="#"
//             className="btn btn-primary"
//             target="_blank"
//             rel="noopener noreferrer"
//           >
//             Start Course
//           </a>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CourseCard;

//api working code for pphyton
// const CourseCard = ({ course }) => {
//   const {
//     name,
//     category,
//     image,
//     actual_price_usd,
//     sale_price_usd,
//     sale_end,
//     description
//   } = course;

//   return (
//     <div className="col-lg-4 col-md-6 mb-4">
//       <div className="card shadow-sm rounded overflow-hidden hover-shadow">
//         <img src={image} className="card-img-top" alt={name} />
//         <div className="card-body">
//           <h5 className="card-title">{name}</h5>
//           {/* <p className="card-text">{description}</p> */}
//           <p className="card-text">
//             <strong>Category:</strong> {category}
//           </p>
//           {/* <p className="card-text">
//             <strong>Price:</strong> ${sale_price_usd === 0 ? 'Free' : sale_price_usd}
//             {actual_price_usd > sale_price_usd && (
//               <span className="text-muted ms-2">
//                 <del>${actual_price_usd}</del>
//               </span>
//             )}
//           </p> */}
//           <p className="card-text">
//             {sale_end && (
//               <small className="text-warning">
//                 Sale ends on: {new Date(sale_end).toLocaleDateString()}
//               </small>
//             )}
//           </p>
//           <a
//             href="#"
//             className="btn btn-primary"
//             target="_blank"
//             rel="noopener noreferrer"
//           >
//             Buy Now
//           </a>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CourseCard;


