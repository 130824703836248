import React, { useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';  // Import useNavigate
import TestCompleteModal from '../components/TestCompleteModal';

const reactQuestions = [
  { id: 1, question: 'What is React?', options: ['Library', 'Framework', 'Language', 'Tool'], answer: 'Library' },
  { id: 2, question: 'What is JSX?', options: ['JavaScript', 'XML', 'Both', 'None'], answer: 'Both' },
  { id: 3, question: 'What is useState used for?', options: ['State', 'Props', 'Component', 'None'], answer: 'State' },
  { id: 4, question: 'What is useState used for?', options: ['State', 'Props', 'Component', 'None'], answer: 'State' },
  { id: 5, question: 'What is useState used for?', options: ['State', 'Props', 'Component', 'None'], answer: 'State' },
  { id: 6, question: 'What is useState used for?', options: ['State', 'Props', 'Component', 'None'], answer: 'State' },
  { id: 7, question: 'What is useState used for?', options: ['State', 'Props', 'Component', 'None'], answer: 'State' },
  { id: 8, question: 'What is useState used for?', options: ['State', 'Props', 'Component', 'None'], answer: 'State' },
  { id: 8, question: 'What is useState used for?', options: ['State', 'Props', 'Component', 'None'], answer: 'State' },
  { id: 9, question: 'What is useState used for?', options: ['State', 'Props', 'Component', 'None'], answer: 'State' },
  { id: 10, question: 'What is useState used for?', options: ['State', 'Props', 'Component', 'None'], answer: 'State' },
  
  // Add more questions as needed
];

const QuizReact = () => {
  const [answers, setAnswers] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [score, setScore] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();  // Get navigate function

  const handleAnswerChange = (e, questionId) => {
    if (!submitted) {  // Allow changes only if the quiz hasn't been submitted yet
      setAnswers({ ...answers, [questionId]: e.target.value });
    }
  };

  const handleSubmit = () => {
    let calculatedScore = 0;
    reactQuestions.forEach((q) => {
      if (answers[q.id] === q.answer) calculatedScore += 1;
    });
    setScore(calculatedScore);
    setSubmitted(true);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);  // Close the result modal
  };

  return (
    <Container className="mt-5 pt-5">
      <h2>React.js Quiz</h2>
      <Form>
        {reactQuestions.map((question) => (
          <div key={question.id} className="mb-3">
            <h5>{question.id}. {question.question}</h5>
            {question.options.map((option, idx) => (
              <Form.Check
                type="radio"
                key={idx}
                label={option}
                name={`question-${question.id}`}
                value={option}
                checked={answers[question.id] === option}
                onChange={(e) => handleAnswerChange(e, question.id)}
                disabled={submitted} // Disable options after submitting
              />
            ))}
          </div>
        ))}
        <Button className="mt-4" onClick={handleSubmit} disabled={submitted}>
          Submit
        </Button>
        <Button className="mt-4 ms-2" variant="secondary" onClick={() => navigate('/')}>
          Back to Home
        </Button>
      </Form>

      {/* Modal for showing quiz results */}
      <TestCompleteModal
        show={showModal}
        score={score}
        total={reactQuestions.length}
        onClose={handleCloseModal}
        answers={answers}
        questions={reactQuestions}
      />
    </Container>
  );
};

export default QuizReact;
