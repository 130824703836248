// src/components/HomePage.js
// import React from 'react';
// import { Carousel, Container, Row, Col, Card } from 'react-bootstrap';
// import ai from "../../src/images/ai.jpg";
// import b from "../../src/images/b.jpg";

// const HomePage = () => {







//   return (
//     <Container className="mt-5 pt-5">
//       {/* AdSense Space */}
//       <div className="my-4 text-center">
//         <img src="https://dummyimage.com/728x90/ccc/000&text=Google+Ad" alt="Google Ad" className="img-fluid" />
//       </div>

//       {/* Carousel */}
//       <Carousel>
//         <Carousel.Item>
//           <img
//             className="d-block w-100"
//             src={ai}
//             alt="First slide"
//           />
//           <Carousel.Caption>
//             {/* <h3>Learning Image 1</h3>
//             <p>Description for Learning Image 1.</p> */}
//           </Carousel.Caption>
//         </Carousel.Item>
//         {/* <Carousel.Item>
//           <img
//             className="d-block w-100"
//             src={b}
//             alt="Second slide"
//           />
//           <Carousel.Caption>
//             <h3>Learning Image 2</h3>
//             <p>Description for Learning Image 2.</p>
//           </Carousel.Caption>
//         </Carousel.Item> */}
//         {/* Add more Carousel.Items as needed */}
//       </Carousel>

//       {/* Content Section */}
//       <div className="my-4">
//         <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum. Donec efficitur, justo et elementum varius, nisi tortor malesuada libero, eget sagittis purus ligula nec nulla. Curabitur vitae massa eu sapien interdum euismod. Fusce vel dolor lorem. Aliquam erat volutpat. Quisque mollis felis ut nisi scelerisque, ut sagittis est aliquam. Integer ultrices velit id neque varius, sed egestas orci blandit. Integer vestibulum velit non metus scelerisque, sit amet fermentum ligula tristique. Nullam ac urna non libero viverra interdum. Etiam sed ex et nisl suscipit condimentum. Nulla facilisi. Nulla vehicula lectus sit amet metus luctus, nec tempus nunc feugiat. Donec fermentum nunc et libero fringilla auctor. Donec ultricies magna id lacus vulputate, eget laoreet libero interdum. Nulla tristique ligula id dui dictum, et facilisis ipsum commodo.</p>
//       </div>

//       {/* Courses by Store Section */}
//       <h2>Courses by Store</h2>
//       <Row>
//         {Array.from({ length: 10 }).map((_, index) => (
//           <Col md={4} lg={3} className="mb-4" key={index}>
//             <Card>
//               <Card.Img variant="top" src={`https://dummyimage.com/200x200/000/fff&text=Image+${index + 1}`} />
//               <Card.Body>
//                 <Card.Title>Category {index + 1}</Card.Title>
//               </Card.Body>
//             </Card>
//           </Col>
//         ))}
//       </Row>
//     </Container>
//   );
// };

// export default HomePage;

import React from 'react';
import { Carousel, Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ai from "../../src/images/ai.jpg";
// import b from "../../src/images/b.jpg";
import Video from './Video'; // Import the Video component


// Quiz topics data
const quizTopics = [
  { id: 1, name: 'React.js', questions: 10, time: '15 min' },
  { id: 2, name: 'Hacking', questions: 10, time: '10 min' },
  { id: 3, name: 'JavaScript', questions: 10, time: '12 min' },
  { id: 4, name: 'AI', questions: 10, time: '20 min' },
  { id: 5, name: 'Azure DevOps', questions: 10, time: '25 min' },
  { id: 6, name: 'Phone', questions: 10, time: '15 min' },
  { id: 7, name: 'Selenium', questions: 10, time: '18 min' },
  { id: 8, name: 'Data Science', questions: 10, time: '20 min' },
];

const HomePage = () => {
  const navigate = useNavigate();

  const startQuiz = (topic) => {
    navigate(`/quiz/${topic.name.toLowerCase()}`);
  };


  return (
    <Container className="mt-5 pt-5">
      {/* AdSense Space */}
      <div className="my-4 text-center">
        <img src="https://dummyimage.com/728x90/ccc/000&text=Google+Ad" alt="Google Ad" className="img-fluid" />
      </div>

      {/* Carousel */}
      <Carousel>
        <Carousel.Item>
          <img className="d-block w-100" src={ai} alt="First slide" />
          <Carousel.Caption>
            {/* <h3>Learning Image 1</h3>
            <p>Description for Learning Image 1.</p> */}
          </Carousel.Caption>
        </Carousel.Item>
        {/* You can add more carousel items as needed */}
      </Carousel>

      {/* Content Section */}
      <div className="my-4">
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum. Donec efficitur, justo et elementum varius, nisi tortor malesuada libero, eget sagittis purus ligula nec nulla. Curabitur vitae massa eu sapien interdum euismod. Fusce vel dolor lorem. Aliquam erat volutpat. Quisque mollis felis ut nisi scelerisque, ut sagittis est aliquam. Integer ultrices velit id neque varius, sed egestas orci blandit. Integer vestibulum velit non metus scelerisque, sit amet fermentum ligula tristique.</p>
      </div>

      {/* Quiz Topics Section */}
      <h2>Quiz Topics</h2>
      <Row>
        {quizTopics.map((topic) => (
          <Col md={4} lg={3} className="mb-4" key={topic.id}>
            <Card>
              <Card.Body>
                <Card.Title>{topic.name}</Card.Title>
                <Card.Text>No of Questions: {topic.questions}</Card.Text>
                <Card.Text>Time: {topic.time}</Card.Text>
                <Button onClick={() => startQuiz(topic)}>Start Quiz</Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      {/* Video Section */}
      <Video />
    </Container>
  );
};

export default HomePage;

